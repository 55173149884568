import { WarehouseService } from "./../../../../service/warehouse/warehouse.service";
import { DebitService } from "./../../../../service/debit/debit.service";
import { SupplierService } from "./../../../../service/partner/supplier.service";
import { CashbookService } from "./../../../../service/cashbook/cashbook.service";
import { StoreService } from "./../../../../service/store/store.service";
import { NzMessageService } from "ng-zorro-antd";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { CustomerService } from "src/app/service/customer/customer.service";

@Component({
  selector: "app-upsert-customer-debit",
  templateUrl: "./upsert-customer-debit.component.html",
  styleUrls: ["./upsert-customer-debit.component.scss"],
})
export class UpsertCustomerDebitComponent implements OnInit {
  @Input() dataEdit: any;
  @Input() isVisible: boolean;
  @Output() closeModal = new EventEmitter();
  validateForm: FormGroup;
  dateFormat = "YYYY/MM/DD";
  cashbooks = [];
  pageIndex = 1;
  pageSize = 10;
  total = 1;
  listOfData = [];
  stores = [];
  loading = true;
  amount = null;
  customers = [];
  loadingCustomer = false;
  filter = {
    offset: 0,
    limit: 2000,
    supplier_id: "0",
  };
  timeId: any = null;
  currentCustomer = null;
  inputSearchCustomer= '';

  constructor(
    private cashbookSV: CashbookService,
    private customerSV: CustomerService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private debitSV: CustomerService,
    private storeSV: StoreService
  ) { }

  ngOnInit() {
    let data = this.dataEdit;
    this.amount = data.amount;
    this.validateForm = this.fb.group({
      store_id: [
        data && data.id ? data.store_id + "" : null,
        [Validators.required],
      ],
      customer_id: [
        data && data.id ? data.customer_id + "" : null,
        [Validators.required],
      ],
      cashbook_source_id: [
        data && data.id ? data.cashbook_source_id + "" : null,
        [Validators.required],
      ],
      date: [
        data && data.id ? new Date(data.date) : null,
        [Validators.required],
      ],
      amount: [data && data.id ? data.amount : null, [Validators.required]],
      note: [data && data.id ? data.note : null],
    });
    this.getCustomerDebit();
    this.cashbookSV.getCashbookSource().subscribe((r) => {
      if (r && r.status == 1) this.cashbooks = r.data;
    });
    this.storeSV.getAllStore().subscribe(r => { if (r && r.status == 1) this.stores = r.data; });
  }

  onSearchCustomer(value) {
    if (value != "") {
      let ft = {
        find: value,
        offset: 0,
        limit: 10,
      };
      clearTimeout(this.timeId);
      this.timeId = setTimeout(() => {
        this.getCustomers(ft);
      }, 500);
    }
  }

  getCustomerDebit() {
    this.filter.offset = (this.pageIndex - 1) * this.pageSize;
    this.filter.limit = this.pageSize;
    this.loading = true;
    this.debitSV.getDebit(this.filter).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfData = res.data;
        this.loading = false;
        this.total = res.total;
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(this.isVisible);
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      let data = this.validateForm.value;
      data = { ...this.dataEdit, ...data };
      data.date = moment(data.date).format("YYYY-MM-DD");

      data.amount = Number(data.amount.toString().replace(/,/g, ""));
      let cus = this.getCurrentCustomer(data.customer_id);
      if (cus && cus.total < data.amount) {
        this.message.create("error", "Số tiền không hợp lệ");
        return;
      }
      this.debitSV.addOrEditDebit(data).subscribe((r) => {
        if (r && r.status == 1) {
          this.message.create(
            "success",
            this.dataEdit && this.dataEdit.id
              ? "Cập nhật thành công!"
              : "Tạo thành công!"
          );
          this.handleCancel();
        } else {
          this.message.create(
            "error",
            r && r.message ? r.message : "Đã có lổi xẩy ra. Vui lòng thử lại!"
          );
        }
      });
    }
  }

  getCurrentCustomer(customer_id) {
    return this.listOfData.find((c) => { return c.customer_id == customer_id });
  }

  chooseCustomer(cus) {
    this.currentCustomer = cus;
    this.validateForm.get('customer_id').setValue(cus.id);
    this.inputSearchCustomer = '';
  }

  getCustomers(ft) {
    this.loadingCustomer = true;
    this.customerSV.getAll(ft).subscribe((r) => {
      if (r && r.status == 1) {
        this.customers = r.data;
        this.loadingCustomer = false;
        if (this.customers.length == 0) {
          this.customers.push({ id: 0, name: "Không tìm thấy khách hàng!" });
        }
      }
    });
  }
}
