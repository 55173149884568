import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { NzModalService, NzMessageService } from "ng-zorro-antd";
import * as moment from "moment";
import { DebitService } from "src/app/service/debit/debit.service";
import { SupplierService } from "src/app/service/partner/supplier.service";
import { WarehouseService } from "src/app/service/warehouse/warehouse.service";
import { CustomerService } from "src/app/service/customer/customer.service";
import { InvoiceService } from "src/app/service/invoice/invoice.service";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  isVisible = false;
  pageIndex = 1;
  pageSize = 20;
  total = 0;
  listOfData = [];
  loading = true;
  isDetail = false;

  pageIndexTran = 1;
  pageSizeTran = 10;
  totalTran = 0;
  listOfDataTran = [];
  loadingTran = false;

  dataEdit: any | null = null;
  filterForm: FormGroup;
  previewImage: string | undefined = "";
  previewVisible = false;
  suppliers = [];
  mapOfExpandData: { [key: string]: boolean } = {};
  filter = {
    limit: 0,
    offset: 0,
    debit: "1",
    find: ""
  };

  filterTran = {
    customer_id: 0,
    limit: 0,
    offset: 0,
  };
  invoice = {};

  constructor(
    private fb: FormBuilder,
    private debitSV: CustomerService,
  ) {}

  getInvoiceDetail(invoice) {
    this.invoice = invoice;
    this.isDetail = true;
  }

  ngOnInit() {
    this.filterForm = this.fb.group({
      find: [""],
    });
    this.getAll();
  }

  getAll() {
    this.filter.offset = (this.pageIndex - 1) * this.pageSize;
    this.filter.limit = this.pageSize;
    this.loading = true;
    let ft = { ...this.filter, onlyDebit: true };

    if (this.filter.debit == "1") {
      ft.onlyDebit = true;
    } else {
      ft.onlyDebit = false;
    }
    this.debitSV.getDebit(ft).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfData = res.data;
        this.loading = false;
        this.total = res.total;
      }
    });
  }

  detail(client) {
    console.log(client);
  }

  closeModal(e) {
    this.isVisible = e;
    this.getDebitHistory();
  }

  handleFilter() {
    this.pageIndex = 1;
    this.getAll();
  }

  formatDate(date, fm) {
    return moment(date).format(fm);
  }

  getDebitHistory() {
    this.filterTran.offset = (this.pageIndexTran - 1) * this.pageSizeTran;
    this.filterTran.limit = this.pageSizeTran;
    this.loadingTran = true;
    this.debitSV.getDebitHistoryById(this.filterTran).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfDataTran = res.data;
        this.loadingTran = false;
        this.totalTran = res.total;
      }
    });
  }

  handleView(data) {
    if (data.customer_id != this.filterTran.customer_id) {
      this.listOfDataTran = [];
      this.pageIndexTran = 1;
      this.pageSizeTran = 10;
      this.totalTran = 0;
      this.filterTran.customer_id = data.customer_id;
      this.getDebitHistory();
    } else {
      this.filterTran.customer_id = 0;
    }
  }
}
