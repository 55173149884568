import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { InvoiceService } from "src/app/service/invoice/invoice.service";
import { UserService } from "src/app/service/user/user.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
  isVisible = false;
  pageIndex = 1;
  pageSize = 20;
  total = 1;
  listOfData = [];
  loading = true;
  dataEdit: any | null = null;
  filterForm: FormGroup;
  sales: [];
  isDetail = false;

  filter = {
    from: moment().format("dd-MM-yyyy"),
    to: moment().format("dd-MM-yyyy"),
    date: [new Date(), new Date()],
    store_id: 0,
    sale_id: null,
    limit: 0,
    offset: 0,
  };

  invoice = {
    type: 1,
    store_id: 0,
    user_id: 0,
    customer_id: 0,
    warehouse_id: 0,
    date_sale: null,
    date_ship: null,
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    totalPrice: 0,
    totalPay: 0,
    totalQuantity: 0,
    discountProduct: 0,
    pay_transfer: null,
    pay_cash: 0,
    discount: null,
    ship_fee: null,
    debit: null,
    data: [],
  };

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private invoiceSv: InvoiceService,
    private userSV: UserService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      find: [""],
    });
    this.filter.store_id = Number(localStorage.getItem("store_id"));
    this.getAll();
    this.getSale();
  }

  getSale() {
    this.userSV.getSale().subscribe((rs) => {
      if (rs && rs.status == 1) {
        this.sales = rs.data;
      }
    });
  }

  getInvoiceDetail(invoice) {
    this.invoice = {...this.invoice,...invoice};
    console.log(this.invoice);
    
    this.invoiceSv.getInvoiceDetails(invoice.id).subscribe((r) => {
      if (r && r.status == 1) {
        this.invoice.data = r.data;
        this.isDetail = true;
      }
    });
  }

  getAll() {
    this.filter.offset = (this.pageIndex - 1) * this.pageSize;
    this.filter.limit = this.pageSize;
    this.filter.from =
      this.filter.date && this.filter.date[0]
        ? moment(this.filter.date[0]).format("DD-MM-YYYY")
        : "";
    this.filter.to =
      this.filter.date && this.filter.date[1]
        ? moment(this.filter.date[1]).format("DD-MM-YYYY")
        : "";
    this.loading = true;
    let ft = {} as any;
    ft = { ...this.filter };
    if (this.filter.sale_id) {
      ft.sale_id = this.filter.sale_id;
    } else {
      delete ft.sale_id;
    }

    this.invoiceSv.getAll(ft).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfData = res.data;
        this.loading = false;
        this.total = res.total;
      }
    });
  }

  filterData() {
    this.pageIndex = 1;
    this.getAll();
  }

  addInvoice() {
    this.router.navigate(["/manager/invoice"]);
  }

  deleteInvoice(id) {
    this.modalService.confirm({
      nzTitle: "Bạn có chắc xóa hóa đơn này?",
      nzOkText: "Xác nhận",
      nzOkType: "danger",
      nzOnOk: () => this.cfDel(id),
      nzCancelText: "Hủy",
    });
  }

  cfDel(id) {
    this.invoiceSv.delete(id).subscribe((r) => {
      if (r && r.status == 1) {
        this.message.create("success", "Xóa đơn hàng thành công!");
        this.getAll();
      } else {
        this.message.create(
          "error",
          r && r.message ? r.message : "Đã có lỗi xẩy ra. Vui lòng thử lại!"
        );
      }
    });
  }
}
