import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd";
import { CustomerService } from "src/app/service/customer/customer.service";

@Component({
  selector: "app-cou-group",
  templateUrl: "./cou-group.component.html",
  styleUrls: ["./cou-group.component.scss"],
})
export class CouGroupComponent implements OnInit {
  @Input() dataEdit: any;
  @Input() isVisible: boolean;
  @Output() closeModal = new EventEmitter();
  validateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customerSV: CustomerService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      active: [this.dataEdit.active ? true: false],
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(this.isVisible);
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.status === "VALID") {
      let client = this.validateForm.value;
      client = { ...this.dataEdit, ...client };
      client.active = client.active ? 1 : 0;
      this.customerSV.updateOrCreateGroup(client).subscribe((r) => {
        if (r && r.status == 1) {
          this.message.create(
            "success",
            this.dataEdit && this.dataEdit.id
              ? "Cập nhật thành công!"
              : "Tạo nhóm khách hàng thành công!"
          );
          this.handleCancel();
        } else {
          this.message.create(
            "error",
            r && r.message ? r.message : "Đã có lổi xẩy ra. Vui lòng thử lại!"
          );
        }
      });
    }
  }
}
