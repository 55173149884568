import { Directive, ElementRef, HostListener, Renderer2, Input, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";

@Directive({
  selector: "[appNumberFormat]",
  providers: [NgModel]
})
export class NumberFormatDirective implements OnInit {
  @Input() ngModel: any; // Nhận giá trị từ [(ngModel)]

  constructor(private el: ElementRef, private renderer: Renderer2, private model: NgModel) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.ngModel) {
        this.formatValue(this.ngModel);
      }
    });
  }

  @HostListener("input", ["$event"]) onInputChange(event: Event) {
    let value = this.el.nativeElement.value.replace(/[^0-9]/g, ""); // Chỉ giữ số

    // Cập nhật giá trị đã format vào input
    this.formatValue(value);
  }

  @HostListener("blur") onBlur() {
    let value = this.el.nativeElement.value.replace(/,/g, ""); // Xóa dấu `,`
    if (!value || +value <= 0) {
      value = ""; // Nếu <= 0 thì xóa giá trị
    }

    this.formatValue(value);
  }

  private formatValue(value: string) {
    if (!value) {
      this.renderer.setProperty(this.el.nativeElement, "value", "");
      this.model.viewToModelUpdate(null); // Cập nhật ngModel
      return;
    }

    let num = parseInt(value, 10);
    if (isNaN(num)) return;

    // Format thành dạng 1,000,000
    let formattedValue = num.toLocaleString("en-US");

    // Cập nhật input với số có dấu `,`
    this.renderer.setProperty(this.el.nativeElement, "value", formattedValue);

    // Cập nhật ngModel với số nguyên (không có dấu `,`)
    this.model.viewToModelUpdate(num);
  }
}
