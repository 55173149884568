import { NotFoundComponent } from "./../container/errPage/not-found/not-found.component";
import { DebitSupplierComponent } from "./../container/debit/debit-supplier/debit-supplier.component";
import { DebitHistoryComponent } from "./../container/debit/debit-history/debit-history.component";
import { MoveOutTransactionComponent } from "./../container/warehouse/transaction/move-out-transaction/move-out-transaction.component";
import { InputTransactionComponent } from "./../container/warehouse/transaction/input-transaction/input-transaction.component";
import { TransactionComponent } from "./../container/warehouse/transaction/transaction.component";
import { ListStoreComponent } from "./../container/store/list-store/list-store.component";
import { ListWarehouseComponent } from "./../container/warehouse/list-warehouse/list-warehouse.component";
import { SettingProductComponent } from "./../container/product/setting-product/setting-product.component";
import { BatchProductComponent } from "./../container/product/batch-product/batch-product.component";
import { ProductComponent } from "./../container/product/product/product.component";
import { AssetComponent } from "./../container/asset/asset.component";
import { ProfileComponent } from "./../container/profile/profile.component";
import { UserComponent } from "./../container/user/user.component";
import { CustomerComponent } from "./../container/customer/customer.component";
import { LoginComponent } from "./../login/login.component";
import { CanActivateService } from "./../service/auth/can-activate.service";
import { DashboardComponent } from "./../container/dashboard/dashboard.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "../container/main/main.component";
import { SupplierComponent } from "../container/partner/supplier/supplier.component";
import { ReportWarehouseComponent } from "../container/warehouse/report-warehouse/report-warehouse.component";
import { CustomerGroupComponent } from "../container/customer-group/customer-group.component";
import { InvoiceComponent } from "../container/invoice/invoice.component";
import { CouInvoiceComponent } from "../container/invoice/cou-invoice/cou-invoice.component";
import { DebitCustomerComponent } from "../container/debit/debit-customer/debit-customer.component";
import { HistoryComponent } from "../container/debit/debit-customer/history/history.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: "manager",
    component: MainComponent,
    canActivate: [CanActivateService],
    data: { role: "LOGIN" },
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      {
        path: "customer",
        data: { role: "admin" },
        component: CustomerComponent,
      },
      {
        path: "customer-group",
        data: { role: "admin" },
        component: CustomerGroupComponent,
      },

      { path: "user", data: { role: "admin" }, component: UserComponent },
      { path: "profile", data: { role: "admin" }, component: ProfileComponent },
      { path: "assets", data: { role: "admin" }, component: AssetComponent },

      //parner
      {
        path: "supplier",
        data: { role: "admin" },
        component: SupplierComponent,
      },

      //product
      { path: "product", component: ProductComponent },
      { path: "batchProduct", component: BatchProductComponent },
      { path: "settingProduct", component: SettingProductComponent },

      //store
      { path: "listStore", component: ListStoreComponent },

      //warehouse
      { path: "list-warehouse", component: ListWarehouseComponent },
      { path: "transaction", component: TransactionComponent },
      { path: "addTransaction", component: InputTransactionComponent },
      { path: "moveOutTransaction", component: MoveOutTransactionComponent },
      { path: "report-warehouse", component: ReportWarehouseComponent },

      //debit
      { path: "customer-debit", component: DebitCustomerComponent },
      { path: "customer-debit-history", component: HistoryComponent },
      { path: "debit-history", component: DebitHistoryComponent },
      { path: "debit-supplier", component: DebitSupplierComponent },

      { path: "invoice-list", component: InvoiceComponent },
      { path: "invoice", component: CouInvoiceComponent },
    ],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
})
export class AppRoutingModule {}
