import { Component, Input, OnInit } from "@angular/core";
import { InvoiceService } from "src/app/service/invoice/invoice.service";

@Component({
  selector: "app-print",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"],
})
export class PrintComponent implements OnInit {
  @Input() invoiceId: string = "";
  invoice = {} as any;
  config = {} as any;
  constructor(private invoiceSv: InvoiceService) {}

  ngOnInit() {
    this.invoiceSv.getInvoiceDetails(this.invoiceId).subscribe((r) => {
      this.invoice = r.data;
      this.invoiceSv.getConfigPrint(this.invoice.store_id).subscribe((r) => {
        this.config = r.data;
      });

      console.log(1,this.invoice);
      console.log(2,this.config);
      
    });
  }
}
