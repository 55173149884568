import { MainService } from "./../main.service";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private http: HttpClient, private mainSV: MainService) {}

  getAll(filter): Observable<any> {
    filter.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/Customer/Search",
        filter,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  updateOrCreateCustomer(cus): Observable<any> {
    cus.api = this.mainSV.getApikey();
    let url = cus.id ? "/api/Customer/Update" : "/api/Customer/Add";
    return this.http
      .post(
        environment.APIHOST + url,
        cus,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  getGroup(): Observable<any> {
    return this.http
      .get(
        environment.APIHOST +
          "/api/Customer/Group/Get?api=" +
          this.mainSV.getApikey(),
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  updateOrCreateGroup(group): Observable<any> {
    group.api = this.mainSV.getApikey();
    let url = group.id
      ? "/api/Customer/Group/update"
      : "/api/Customer/Group/add";
    return this.http
      .post(
        environment.APIHOST + url,
        group,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  getDebit(filter): Observable<any> {
    filter.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/Debit/Customer",
        filter,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  getDebitHistory(filter): Observable<any> {
    filter.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/Debit/Customer/History",
        filter,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  getDebitHistoryById(filter): Observable<any> {
    filter.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/Debit/Customer/TransactionDebit",
        filter,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  deleteDebit(id): Observable<any> {
    let filter = {
      id: id.id,
      api: this.mainSV.getApikey(),
    };
    return this.http
      .post(
        environment.APIHOST + "/api/Debit/Customer/Delete",
        filter,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  addOrEditDebit(debit): Observable<any> {
    debit.api = this.mainSV.getApikey();
    let url = "/api/Debit/Customer/Add";
    return this.http
      .post(
        environment.APIHOST + url,
        debit,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }
}
