import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appNumberFormat]"
})
export class NumberFormatDirective {
 constructor(private el: ElementRef, private renderer: Renderer2) {}

 // Lắng nghe sự kiện input
 @HostListener("input", ["$event"]) onInputChange(event: Event) {
   let value = this.el.nativeElement.value.replace(/[^0-9]/g, "");

   // Format số theo dạng 1,000,000
   let formattedValue = this.formatNumber(value);

   // Cập nhật lại giá trị vào input
   this.renderer.setProperty(this.el.nativeElement, "value", formattedValue);

   // Tạo và kích hoạt sự kiện input để ngModel nhận diện thay đổi
   this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
 }

 // Lắng nghe sự kiện blur
 @HostListener("blur", ["$event"]) onBlur() {
   let value = this.el.nativeElement.value.replace(/,/g, "");
   if (+value <= 0) {
     value = ""; // Reset nếu số <= 0
     this.renderer.setProperty(this.el.nativeElement, "value", "");
   }

   // Tạo và kích hoạt sự kiện input để ngModel nhận diện thay đổi
   this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
 }

 // Hàm format số (dạng 1,000,000)
 private formatNumber(value: string): string {
   return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }
}
