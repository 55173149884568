import { SupplierService } from "./../../../service/partner/supplier.service";
import { DebitService } from "./../../../service/debit/debit.service";
import { CustomerService } from "./../../../service/customer/customer.service";
import { environment } from "./../../../../environments/environment.prod";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { NzModalService, NzMessageService } from "ng-zorro-antd";
import * as moment from "moment";

@Component({
  selector: "app-debit-customer",
  templateUrl: "./debit-customer.component.html",
  styleUrls: ["./debit-customer.component.scss"],
})
export class DebitCustomerComponent implements OnInit {
  isVisible = false;
  pageIndex = 1;
  pageSize = 20;
  total = 1;
  listOfData = [];
  loading = true;
  dataEdit: any | null = null;
  filterForm: FormGroup;
  previewImage: string | undefined = "";
  previewVisible = false;
  dateFormat = "yyyy/MM/dd";

  filter = {
    find: "",
    limit: 20,
    offset: 0,
    debit: "1",
    onlyDebit: true,
    from: '',
    to: '',
    date: [],
  };

  constructor(
    private modalService: NzModalService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private customerDb: CustomerService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      find: [""],
    });
    this.getAll();
  }

  getAll() {
    this.filter.offset = (this.pageIndex - 1) * this.pageSize;
    this.filter.limit = this.pageSize;
    this.loading = true;
    this.filter.onlyDebit = this.filter.debit == "1" ? true : false;
    if (this.filter.date && this.filter.date.length > 0) {
      this.filter.from =
        this.filter.date && this.filter.date[0]
          ? moment(this.filter.date[0]).format("YYYY-MM-DD")
          : "";
      this.filter.to =
        this.filter.date && this.filter.date[1]
          ? moment(this.filter.date[1]).format("YYYY-MM-DD")
          : "";
    }else{
      this.filter.from = '';
      this.filter.to = '';
    }

    this.customerDb.getDebitHistory(this.filter).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfData = res.data;
        this.loading = false;
        this.total = res.total;
      }
    });
  }

  handleCorU(client) {
    this.dataEdit = client ? client : {};
    this.isVisible = true;
  }

  closeModal(e) {
    this.isVisible = e;
    this.getAll();
  }

  handleFilter() {
    this.pageIndex = 1;
    this.getAll();
  }

  handlePreviewImg = (url) => {
    this.previewImage = environment.APICURRENTSERVE + "/" + url;
    this.previewVisible = true;
  };

  formatDate(date, fm) {
    return moment(date).format("DD/MM/YYYY");
  }

  confirmDelete(id) {
    this.modalService.confirm({
      nzTitle: "Bạn có chắc xóa dòng này?",
      nzOkText: "Xác nhận",
      nzOkType: "danger",
      nzOnOk: () => this.delete({ id: id }),
      nzCancelText: "Hủy",
    });
  }

  delete(objId) {
    this.customerDb.deleteDebit(objId).subscribe((r) => {
      if (r && r.status == 1) {
        this.message.create("success", "Xóa thành công!");
        this.getAll();
      } else {
        this.message.create(
          "error",
          r && r.message ? r.message : "Có lỗi xẩy ra. Vui lòng thử lại!"
        );
      }
    });
  }
}
