import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { CustomerService } from "src/app/service/customer/customer.service";
import { SupplierService } from "src/app/service/partner/supplier.service";
import { ProductService } from "src/app/service/product/product.service";
import { UserService } from "src/app/service/user/user.service";
import { WarehouseService } from "src/app/service/warehouse/warehouse.service";
import * as moment from "moment";
import { InvoiceService } from "src/app/service/invoice/invoice.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-update-invoice",
  templateUrl: "./update-invoice.component.html",
  styleUrls: ["./update-invoice.component.scss"],
})
export class UpdateInvoiceComponent implements OnInit {
  @Input() invoiceId: number;
  @Output() onReponse = new EventEmitter<string>();

  validateForm: FormGroup;
  customerForm: FormGroup;

  isVisibleProduct = false;

  warehouses = [];
  products = [];
  customers = [];
  invoiceProduct = [];
  isAddCustomer = false;
  isInfo = false;

  loading = {
    addProduct: false,
    addInvoice: false,
    loadingProuct: false,
    loadingCustomer: false,
  };

  inputSearchProd = "";
  inputSearchCustomer = "";
  timeId: any = null;

  groups = [];

  invoice = {
    id: 0,
    type: 1,
    store_id: 0,
    user_id: 0,
    customer_id: 0,
    warehouse_id: "",
    date_sale: null,
    date_ship: null,
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    totalPrice: 0,
    totalPay: 0,
    totalQuantity: 0,
    discountProduct: 0,
    pay_transfer: null,
    pay_cash: 0,
    discount: null,
    ship_fee: null,
    debit: null,
    data: [],
    sale_id: "",
    totalDetail: 0,
    discount_all: 0,
    note: ''
  };

  sales = [];

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private productSV: ProductService,
    private warehouseSV: WarehouseService,
    private customerSV: CustomerService,
    private userSV: UserService,
    private invoiceSV: InvoiceService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.invoice.id = this.invoiceId;
    this.loading.addInvoice = true;
    this.invoiceSV.getInvoiceDetails(this.invoiceId).subscribe((r) => {
      this.invoice = { ...this.invoice, ...r.data };
      this.invoiceProduct = r.data.detail ? r.data.detail : [];

      this.invoice.sale_id = this.invoice.sale_id + "";
      this.invoice.warehouse_id = this.invoice.warehouse_id + "";


      const user = JSON.parse(localStorage.getItem("x-key-x-u-log"));
      const store_id = localStorage.getItem("store_id");
      this.invoice.store_id = store_id ? Number(store_id) : 0;
      this.invoice.user_id = user && user.id ? user.id : 0;

      const dSale = moment(this.invoice.date_sale, 'YYYY-MM-DD');
      const dateSale = dSale.toDate();
      
      const dShip = moment(this.invoice.date_ship, 'YYYY-MM-DD');
      const dateShip = dShip.toDate();

      this.validateForm = this.fb.group({
        warehouse_id: [this.invoice.warehouse_id, [Validators.required]],
        debit: [this.invoice.debit],
        ship_fee: [this.invoice.ship_fee],
        discount: [this.invoice.discount],
        pay_transfer: [this.invoice.pay_transfer],
        note: [this.invoice.note],
        sale_id: [this.invoice.sale_id],
        date_sale: [dateSale],
        date_ship: [dateShip],
      });
      this.loading.addInvoice = false;

      this.getgroupCustomers();
      this.getSale();
      this.getWarehouse();
      this.changeProduct();
    });
  }

  getSale() {
    this.userSV.getSale().subscribe((rs) => {
      if (rs && rs.status == 1) {
        this.sales = rs.data;
      }
    });
  }

  getWarehouse() {
    this.warehouseSV.getAllWH(this.invoice.store_id).subscribe((rs) => {
      if (rs && rs.status == 1) {
        this.warehouses = rs.data;
      }
    });
  }

  getgroupCustomers() {
    this.customerSV.getGroup().subscribe((rs) => {
      this.groups = rs.data;
    });
  }

  getProducts(ft) {
    this.loading.loadingProuct = true;
    this.productSV.searchProduct(ft).subscribe((r) => {
      if (r && r.status == 1) {
        this.products = r.data;

        if (this.products.length == 0) {
          this.products.push({ id: 0, name: "Không tìm thấy sản phẩm!" });
        }
        this.loading.loadingProuct = false;
      }
    });
  }

  onSearchProduct(value) {
    if (value != "") {
      let ft = {
        find: value,
        offset: 0,
        limit: 10,
      };
      clearTimeout(this.timeId);
      this.timeId = setTimeout(() => {
        this.getProducts(ft);
      }, 500);
    }
  }

  chooseProd(val) {
    if (val > 0) {
      let prod = this.products.find((r) => {
        return r.id == val;
      });
      let checkHasProd = this.invoiceProduct.find((r) => {
        return r.product_id == val;
      });

      if (checkHasProd) {
        this.message.create("error", "Bạn đã chọn hàng hóa này!");
      } else {
        if (prod) {
          let product = {
            product_id: prod.id,
            quantity: 1,
            price: prod.price,
            cost: prod.cost,
            product_name: prod.name,
            product_code: prod.code,
            unit_name: prod.unit_name,
            specification_name: prod.specification_name,
            discount: null,
            totalPay: prod.price,
          };
          this.invoiceProduct.unshift(product);
          this.changeProduct();
        }
      }
    }
    this.inputSearchProd = "";
  }

  deleteProduct(prod, products, index) {
    this.invoiceProduct.splice(index, 1);
    this.changeProduct();
  }

  changeProduct() {
    setTimeout(() => {

      this.invoice.discount = this.validateForm.value.discount
      this.invoice.debit = this.validateForm.value.debit
      this.invoice.ship_fee = this.validateForm.value.ship_fee
      this.invoice.pay_transfer = this.validateForm.value.pay_transfer

      this.invoice.totalPay = 0;
      this.invoice.totalPrice = 0;
      this.invoice.totalQuantity = 0;
      this.invoice.discountProduct = 0;
      this.invoice.pay_cash = 0;
      this.invoice.totalDetail = 0;

      this.invoiceProduct.forEach((prd) => {        
        let price = this.fmNum(prd.price) * this.fmNum(prd.quantity);

        prd.totalPay =
          price -
          (prd.discount
            ? this.fmNum(prd.discount) * this.fmNum(prd.quantity)
            : 0);

        this.invoice.totalPay += prd.totalPay;

        this.invoice.totalPrice += price;

        this.invoice.discountProduct += prd.discount
          ? this.fmNum(prd.discount) * this.fmNum(prd.quantity)
          : 0;

        this.invoice.totalQuantity += this.fmNum(prd.quantity);
        this.invoice.totalDetail += this.fmNum(prd.totalPay);
      });

      if (this.invoice.debit) {
        this.invoice.totalPay =
          this.invoice.totalPay - this.fmNum(this.invoice.debit);
      }

      if (this.invoice.discount) {
        this.invoice.totalPay =
          this.invoice.totalPay - this.fmNum(this.invoice.discount);
      }

      if (this.invoice.ship_fee) {
        this.invoice.totalPay =
          this.invoice.totalPay + this.fmNum(this.invoice.ship_fee);
      }

      this.invoice.pay_cash =
        this.fmNum(this.invoice.totalPay) -
        this.fmNum(this.invoice.pay_transfer);

      this.invoice.discount_all =
        this.fmNum(this.invoice.discountProduct) +
        this.fmNum(this.invoice.discount);
    }, 100);
  }

  fmNum(val) {
    if (!val) return 0;

    val = val
      .toString()
      .replace(",", "")
      .replace(",", "")
      .replace(",", "")
      .replace(",", "");
    return val ? Number(val) : 0;
  }

  saveInvoice(): void {
    if (!this.invoiceProduct.length) {
      this.message.create("error", "Vui lòng chọn hàng hóa!");
      return;
    }

    if (!this.invoice.customer_id) {
      this.message.create("error", "Vui lòng chọn khách hàng!");
      return;
    }

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.status === "VALID") {
      if (this.validateForm.value.date_sale) {
        this.invoice.date_sale = moment(
          this.validateForm.value.date_sale
        ).format("YYYY-MM-DD");
      }
      if (this.validateForm.value.date_ship) {
        this.invoice.date_ship = moment(
          this.validateForm.value.date_ship
        ).format("YYYY-MM-DD");
      }

      this.invoice.warehouse_id = this.validateForm.value.warehouse_id;
      this.invoice.sale_id = this.validateForm.value.sale_id;
      this.invoice.note = this.validateForm.value.note;

      let invoice = {} as any;
      invoice = Object.assign({}, this.invoice);

      invoice.pay_transfer = this.fmNum(invoice.pay_transfer);
      invoice.discount = this.fmNum(invoice.discount);
      invoice.debit = this.fmNum(invoice.debit);
      invoice.ship_fee = this.fmNum(invoice.ship_fee);

      invoice.data = this.invoiceProduct.map((invoice) => {
        return {
          product_id: invoice.product_id,
          product_batch_id: 0,
          quantity: this.fmNum(invoice.quantity),
          price: this.fmNum(invoice.price),
          discount: this.fmNum(invoice.discount),
          cost: this.fmNum(invoice.cost),
        };
      });

      invoice.debit = this.fmNum(invoice.debit);
      invoice.discount = this.fmNum(invoice.discount);
      invoice.ship_fee = this.fmNum(invoice.ship_fee);

      this.modalService.confirm({
        nzTitle: "Bạn có chắc cập nhật hóa đơn này?",
        nzOkText: "Xác nhận",
        nzOkType: "danger",
        nzOnOk: () => this.cfUpdate(invoice),
        nzCancelText: "Hủy",
      });
    }
  }

  cfUpdate(iv) {
    this.loading.addInvoice = true;
    if (!iv.sale_id) iv.sale_id = 0;

    this.invoiceSV.updateInvoice(iv).subscribe((r) => {
      if (r && r.status == 1) {
        this.message.create("success", "Cập nhật hóa đơn thành công!");
        this.onReponse.emit("success");
      } else {
        this.message.create(
          "error",
          r && r.message ? r.message : "Đã có lỗi xẩy ra. Vui lòng thử lại!"
        );
      }
      this.loading.addInvoice = false;
    });
    this.loading.addInvoice = false;
  }

  // Validator cho số điện thoại
  phoneNumberValidator(control: any) {
    const value = control.value;
    const phoneNumberPattern = /^0\d{9}$/;

    if (!value) {
      return null;
    }

    if (!phoneNumberPattern.test(value)) {
      return { phoneNumberInvalid: true };
    }

    return null;
  }
}
