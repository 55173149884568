import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { throwError } from "rxjs";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class MainService {
  private token = localStorage.getItem("token");
  private origin = "";
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.origin = this.document.location.origin;
  }

  getHttpOptions() {
    let user = this.getCurrentUser();

    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("user_id", user.id ? String(user.id) : "")
      .set("api", user.api ? String(user.api) : "");
    
    return { headers };
  }

  getCurrentUser() {
    let user = localStorage.getItem("x-key-x-u-log");
    return user ? JSON.parse(user) : {};
  }

  getApikey() {
    let user = JSON.parse(localStorage.getItem("x-key-x-u-log"));
    return user.api ? user.api : "";
  }

  getHttpOptionsNotToken() {
    let user = this.getCurrentUser();

    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("user_id", user.id ? String(user.id) : "")
      .set("api", user.api ? String(user.api) : "");
    
    return { headers };
  }

  host() {
    return this.origin;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error("An error occurred:", error);
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
