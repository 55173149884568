import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appOnlyPhone]",
})
export class OnlyPhoneDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  // Lắng nghe sự kiện input
  @HostListener("input", ["$event"]) onInputChange(event: Event) {
    // Chỉ cho phép nhập ký tự số
    let value = this.el.nativeElement.value.replace(/[^0-9]/g, "");

    // Đảm bảo số đầu tiên là 0 nếu có nhập liệu
    if (value.length > 0 && value[0] !== "0") {
      value = "0" + value;
    }

    // Giới hạn tối đa là 10 số
    value = value.slice(0, 10);

    // Cập nhật giá trị vào input
    this.renderer.setProperty(this.el.nativeElement, "value", value);

    // Kích hoạt sự kiện input để ngModel nhận diện thay đổi
    this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
  }

  // Lắng nghe sự kiện blur
  @HostListener("blur", ["$event"]) onBlur() {
    const value = this.el.nativeElement.value;

    // Nếu không phải số điện thoại hợp lệ thì cảnh báo
    if (value.length !== 10 || value[0] !== "0") {
      // Hiển thị cảnh báo (bạn có thể thay thế bằng message khác nếu cần)
    }
  }
}
