import { MainService } from "./../main.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  constructor(private http: HttpClient, private mainSV: MainService) {}
  getAll(body): Observable<any> {
    body.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/invoice/Get",
        body,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  updateOrCreate(suplierObj): Observable<any> {
    suplierObj.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/supplier/add",
        suplierObj,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  addInvoice(invoice): Observable<any> {
    invoice.api = this.mainSV.getApikey();
    return this.http
      .post(
        environment.APIHOST + "/api/invoice/Add",
        invoice,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  getInvoiceDetails(id): Observable<any> {
    let ft = {
      api: this.mainSV.getApikey(),
      invoice_id: id,
    };

    return this.http
      .post(
        environment.APIHOST + "/api/Invoice/Detail",
        ft,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }

  delete(id): Observable<any> {
    let ft = {
      api: this.mainSV.getApikey(),
      invoice_id: id,
    };

    return this.http
      .post(
        environment.APIHOST + "/api/Invoice/Delete",
        ft,
        this.mainSV.getHttpOptionsNotToken()
      )
      .pipe(catchError(this.mainSV.handleError));
  }
}
