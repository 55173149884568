import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { InvoiceService } from "src/app/service/invoice/invoice.service";
import { StoreService } from "src/app/service/store/store.service";
import { UserService } from "src/app/service/user/user.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
  isVisible = false;
  pageIndex = 1;
  pageSize = 20;
  total = 1;
  listOfData = [];
  loading = true;
  dataEdit: any | null = null;
  filterForm: FormGroup;
  sales: [];
  isDetail = false;
  isEdit = false;
  isReturn = false;
  stores = [];
  filter = {
    from: moment().format("dd-MM-yyyy"),
    to: moment().format("dd-MM-yyyy"),
    date: [],
    store_id: null,
    sale_id: null,
    find: null,
    limit: 0,
    offset: 0,
  };

  invoice = {
    type: 1,
    store_id: 0,
    user_id: 0,
    customer_id: 0,
    warehouse_id: 0,
    date_sale: null,
    date_ship: null,
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    totalPrice: 0,
    totalPay: 0,
    totalQuantity: 0,
    discountProduct: 0,
    pay_transfer: null,
    pay_cash: 0,
    discount: null,
    ship_fee: null,
    debit: null,
    data: [],
  };

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private invoiceSv: InvoiceService,
    private userSV: UserService,
    private storeSV: StoreService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      find: [""],
    });
    // this.filter.store_id = Number(localStorage.getItem("store_id"));
    this.getAll();
    this.getSale();
    this.getStore();
  }

  getSale() {
    this.userSV.getSale().subscribe((rs) => {
      if (rs && rs.status == 1) {
        this.sales = rs.data;
      }
    });
  }

  getStore() {
    this.storeSV.getAllStore().subscribe((rs) => {
      if (rs && rs.status == 1) {
        this.stores = rs.data;
      }
    });
  }

  getInvoiceDetail(invoice) {
    this.invoice = { ...this.invoice, ...invoice };
    this.isDetail = true;
  }

  getAll() {
    this.filter.offset = (this.pageIndex - 1) * this.pageSize;
    this.filter.limit = this.pageSize;
    this.filter.from =
      this.filter.date && this.filter.date[0]
        ? moment(this.filter.date[0]).format("DD-MM-YYYY")
        : "";
    this.filter.to =
      this.filter.date && this.filter.date[1]
        ? moment(this.filter.date[1]).format("DD-MM-YYYY")
        : "";
    this.loading = true;
    let ft = {} as any;
    ft = { ...this.filter };
    if (this.filter.sale_id) {
      ft.sale_id = this.filter.sale_id;
    } else {
      delete ft.sale_id;
    }

    if (!ft.store_id) ft.store_id = 0;

    this.invoiceSv.getAll(ft).subscribe((res) => {
      if (res && res.status == 1) {
        this.listOfData = res.data;
        this.loading = false;
        this.total = res.total;
      }
    });
  }

  filterData() {
    this.pageIndex = 1;
    this.getAll();
  }

  addInvoice() {
    this.router.navigate(["/manager/invoice"]);
  }

  returnInvoiceUrl() {
    this.router.navigate(["/manager/invoice"], { queryParams: { type_id: 2 } });
  }

  editInvoice(data) {
    this.invoice = data;
    this.isEdit = true;
  }

  returnInvoice(data) {
    this.invoice = data;
    this.isReturn = true;
  }

  deleteInvoice(id) {
    this.modalService.confirm({
      nzTitle: "Bạn có chắc xóa hóa đơn này?",
      nzOkText: "Xác nhận",
      nzOkType: "danger",
      nzOnOk: () => this.cfDel(id),
      nzCancelText: "Hủy",
    });
  }

  onSuccessUpdate() {
    this.getAll();
    this.isEdit = false;
  }

  onSuccessReturn() {
    this.filterData();
    this.isReturn = false;
  }

  cfDel(id) {
    this.invoiceSv.delete(id).subscribe((r) => {
      if (r && r.status == 1) {
        this.message.create("success", "Xóa đơn hàng thành công!");
        this.getAll();
      } else {
        this.message.create(
          "error",
          r && r.message ? r.message : "Đã có lỗi xẩy ra. Vui lòng thử lại!"
        );
      }
    });
  }

  onIdClick(event: Event) {
    event.stopPropagation();
  }

  printInvoice(invoice: any) {
    // Lấy dữ liệu hóa đơn chi tiết
    this.invoiceSv.getInvoiceDetails(invoice.id).subscribe((r) => {
      if (r && r.status == 1) {
        let invoiceData = r.data;
        this.invoiceSv.getConfigPrint(invoiceData.store_id).subscribe((r) => {
          const config = r.data.receipt_config;
          invoiceData.type_id = invoice.type_id;
          this.printHtmlBuilder(invoiceData, config);
        });
      } else {
        console.error("Không lấy được dữ liệu hóa đơn");
      }
    });
  }

  printHtmlBuilder(invoice, config) {
    // Tạo cửa sổ in
    const printWindow = window.open("", "_blank", "width=1000,height=800");
    if (!printWindow) {
      console.error("Trình duyệt chặn popup");
      return;
    }

    printWindow.document.write(`
      <!DOCTYPE html>
        <html lang="vi">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Hóa Đơn</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                }
                .invoice-header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .invoice-header h2 {
                    margin: 0;
                }
                .invoice-details, .invoice-items {
                    width: 100%;
                    border-collapse: collapse;
                }
                .invoice-details td, .invoice-items th, .invoice-items td {
                    border: 1px solid #ddd;
                    padding: 5px;
                }
                .invoice-items th {
                    background-color: #f4f4f4;
                }
                .total {
                    text-align: right;
                    font-weight: bold;
                }
                .print-btn {
                    display: block;
                    margin: 20px auto;
                    padding: 10px 20px;
                    font-size: 16px;
                    background-color: #28a745;
                    color: white;
                    border: none;
                    cursor: pointer;
                }
                .print-btn:hover {
                    background-color: #218838;
                }

                .text-center{
                  text-align: center;
                }
                  .text-right {
                  text-align: right;
                  }
                  .text-left {
                  text-align: left;
                  }
            </style>
        </head>
        <!-- onload="window.print(); window.close();" -->
        <body onload="window.print(); window.close();">
            <div class="invoice-container">
                <div style="display: flex">
                  <div style="flex: 1">
                      <img height="60px" src="${config.logo_url}" /> 
                  </div>
                  <div style="flex: 2">
                      <div style="display: flex">
                        <div style="flex: 1">
                          <span><strong>Chi nhánh</strong>: ${
                            config.store_name
                          }</span>
                        </div>
                        <div style="flex: 1">
                          <span><strong>Liên hệ</strong>: ${
                            config.store_phone
                          }</span>
                        </div>
                      </div>
                      <span><strong>Địa chỉ</strong>: ${
                        config.store_address
                      }</span>
                  </div>
                </div>

                <div class="invoice-header" style="margin-top:15px">
                    <h2>${
                      invoice.type_id == 1
                        ? "HÓA ĐƠN BÁN HÀNG"
                        : "HÓA ĐƠN TRẢ HÀNG"
                    }</h2>
                    <span>HĐ: ${invoice.invoice_id}</span>
                </div>

                <div style="display:flex">
                  <div style="flex: 1">
                    <strong>Khách hàng:</strong> ${invoice.customer_name}
                  </div>
                  <div style="flex: 1">
                    <strong>Ngày:</strong> ${invoice.date_sale}
                  </div>
                </div>

                <div style="display:flex">
                  <div style="flex: 1">
                    <strong>Liên hệ:</strong> ${invoice.customer_phone}
                  </div>
                </div>

                <div style="display:flex">
                  <div style="flex: 1">
                    <strong>Địa chỉ:</strong> ${
                      invoice.customer_address ? invoice.customer_address : ""
                    }
                  </div>
                </div>

                <table class="invoice-items" style="margin-top:30px">
                    <tr>
                        <th class="text-center">STT</th>
                        <th class="text-left">Hàng hóa</th>
                        <th class="text-center">SL</th>
                        <th class="text-center">Đơn giá</th>
                        <th class="text-center">Giảm giá</th>
                        <th class="text-center">Thành tiền</th>
                    </tr>
                    ${invoice.detail
                      .map((r, index) => {
                        return `
                          <tr>
                            <td class="text-center">${index + 1}</td>
                            <td class="text-left">
                              <span>${r.product_name}</span> <br>
                              <small>${r.product_code} </small>
                            </td>
                            <td class="text-center">${r.quantity.toLocaleString(
                              "en-US"
                            )}</td>
                            <td class="text-right">${r.price.toLocaleString(
                              "en-US"
                            )} </td>
                            <td class="text-right">${r.discount.toLocaleString(
                              "en-US"
                            )} </td>
                            <td class="text-right">${r.total.toLocaleString(
                              "en-US"
                            )} </td>
                          </tr>`;
                      })
                      .join("")}
                </table>

                <div style="margin-top:20px;display:flex">
                    <div style="flex: 1">
                      <strong>
                        Người bán:
                      </strong>
                        <span> ${
                          invoice.sale_name ? invoice.sale_name : ""
                        }</span>
                      <br>
                      <strong>
                        Ghi chú: 
                      </strong>
                      <span>${
                        invoice.note ? invoice.note : "Không có ghi chú!"
                      }</span>
                    </div>
                    <div style="flex: 1">
                      <div style="display:flex">

                          <div style="flex: 1" class="text-right">
                              <strong>Giao hàng</strong>
                          </div>

                          <div style="flex: 1" class="text-right">
                              ${invoice.ship_fee.toLocaleString("en-US")}
                          </div>
                      </div>

                      <div style="display:flex;margin-top:10px">

                          <div style="flex: 1" class="text-right">
                              <strong>Giảm giá</strong>

                          </div>

                          <div style="flex: 1" class="text-right">
                            ${(
                              invoice.discount + invoice.discount_detail
                            ).toLocaleString("en-US")}
                          </div>
                      </div>

                       <div style="display:flex;margin-top:10px">

                          <div style="flex: 1" class="text-right">
                              <strong>Nợ lại</strong>

                          </div>

                          <div style="flex: 1" class="text-right">
                            ${invoice.pay_debit.toLocaleString("en-US")}
                          </div>
                      </div>

                      <div style="display:flex;margin-top:10px">

                          <div style="flex: 1" class="text-right">
                              <strong>Tổng tiền</strong>

                          </div>

                          <div style="flex: 1" class="text-right">
                          ${invoice.total.toLocaleString("en-US")}
                          </div>
                      </div>

                       <div style="display:flex;margin-top:10px">

                          <div style="flex: 1" class="text-right">
                              <strong>Chuyển khoản</strong>

                          </div>

                          <div style="flex: 1" class="text-right">
                            ${invoice.pay_transfer.toLocaleString("en-US")}
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </body>
        </html>

    `);

    printWindow.document.close();
  }
}
