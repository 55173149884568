import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appFloatFormat]",
})
export class FloatFormatDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("input", ["$event"]) onInputChange(event: Event) {
    let value = this.el.nativeElement.value;

    // Chỉ giữ lại các ký tự số và dấu chấm thập phân
    value = value.replace(/[^0-9.]/g, "");

    // Kiểm tra số chữ số thập phân (tối đa 2)
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("").substring(0, 2);
    } else if (parts.length === 2 && parts[1].length > 2) {
      value = parts[0] + "." + parts[1].substring(0, 2);
    }

    // Cập nhật lại giá trị vào input
    this.renderer.setProperty(this.el.nativeElement, "value", value);

    // Tạo và kích hoạt sự kiện input để ngModel nhận diện thay đổi
    this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
  }

  @HostListener("blur", ["$event"]) onBlur() {
    let value = this.el.nativeElement.value;
    if (!value || parseFloat(value) <= 0) {
      value = "1"; // Reset nếu số <= 0
      this.renderer.setProperty(this.el.nativeElement, "value", "1");
    }

    // Tạo và kích hoạt sự kiện input để ngModel nhận diện thay đổi
    this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
  }
}