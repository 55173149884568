import { Directive, ElementRef, HostListener, Renderer2, Input } from "@angular/core";

@Directive({
  selector: "[appFloatFormat]",
})
export class FloatFormatDirective {
  @Input() maxValue?: number; // Giá trị tối đa có thể nhập

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("input", ["$event"]) onInputChange(event: Event) {
    let value = this.el.nativeElement.value;

    // Chỉ giữ lại các ký tự số và dấu chấm thập phân, không cho nhập nhiều dấu "."
    value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");

    // Kiểm tra số chữ số thập phân (tối đa 3)
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join(".").substring(0, 3);
    } else if (parts.length === 2 && parts[1].length > 3) {
      value = parts[0] + "." + parts[1].substring(0, 3);
    }

    // Nếu chỉ nhập ".", thay bằng "0."
    if (value === ".") value = "0.";

    // Chuyển về số để kiểm tra maxValue
    let numValue = parseFloat(value);
    if (!isNaN(numValue) && this.maxValue !== undefined && numValue > this.maxValue) {
      numValue = this.maxValue;
    }

    // Định dạng phần nguyên với dấu phân tách hàng nghìn
    if (!isNaN(numValue)) {
      const integerPart = Math.floor(numValue).toLocaleString("en-US");
      const decimalPart = parts.length === 2 ? "." + parts[1] : "";
      value = integerPart + decimalPart;
    }

    // Giữ nguyên giá trị nhập vào (không format ngay để tránh mất dấu ".")
    this.renderer.setProperty(this.el.nativeElement, "value", value);

    // Kích hoạt sự kiện input để ngModel nhận diện thay đổi
    this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
  }

  @HostListener("blur") onBlur() {
    let value = this.el.nativeElement.value.replace(/,/g, ""); // Xóa dấu `,`
    let numValue = parseFloat(value);

    if (isNaN(numValue) || numValue <= 0) {
      numValue = 0; // Nếu <= 0 thì set lại về 0
    } else if (this.maxValue !== undefined && numValue > this.maxValue) {
      numValue = this.maxValue; // Nếu lớn hơn maxValue, set lại bằng maxValue
    }

    // Format lại giá trị sau khi mất focus với dấu phân tách hàng nghìn
    const formattedValue = numValue.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    });

    this.renderer.setProperty(this.el.nativeElement, "value", formattedValue);

    // Kích hoạt sự kiện input để ngModel nhận diện thay đổi
    this.el.nativeElement.dispatchEvent(new Event("input", { bubbles: true }));
  }
}
